<template>
  <div class="user">
    <div class="user-t">
      <div class="img" @click="selectType">
        <img src="./img/people.png" alt="" />
      </div>
      <div class="txt" @click="selectType">{{ userTypeMap[form.role] }}</div>
      <div class="arrow" @click="selectType">
        <img v-if="isshow" src="./img/upArrow.png" alt="" />
        <img v-else src="./img/arrow.png" alt="" />
      </div>
      <div
        class="typeUlList"
        v-if="isshow"
        :style="{ bottom: -newUserType.length * 38 + 'px' }"
      >
        <div
          class="li"
          v-for="(item, index) in newUserType"
          :key="index"
          @click="selectLi(item)"
        >
          {{ item.text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { userTypeMap, userType } from "../map";

export default {
  name: "user",
  data() {
    return {
      userType,
      newUserType: [],
      userTypeMap,
      isshow: false,
      form: {
        role: "1",
      },
    };
  },
  async created() {
    let userInfo = sessionStorage.getItem("userInfo");
    if (userInfo) {
      let role = JSON.parse(userInfo).role;
      let arr = role.split(",");
      arr.forEach((element) => {
        let obj = {
          value: element,
          text: userTypeMap[element],
        };
        this.newUserType.push(obj);
      });
    }
    this.form.role = sessionStorage.getItem("role");
  },
  mounted() {},
  methods: {
    selectLi(item) {
      this.form.role = item.value;
      sessionStorage.setItem("role", this.form.role);
      this.$store.commit("setRole", this.form.role);
      this.isshow = false;
      this.swithchPage();
    },
    swithchPage() {
      if (this.form.role == 1) {
        this.$router.push({
          name: "lawEnforcement-escalation",
        });
      }
      if (this.form.role == 2) {
        this.$router.push({
          name: "lawEnforcement-handle",
        });
      }
      if (this.form.role == 3) {
        this.$router.push({
          name: "lawEnforcement-manager",
        });
      }
    },
    selectType() {
      this.isshow = !this.isshow;
    },
  },
};
</script>

<style lang="less" scoped>
.user {
  background: #f8f8f8;

  .user-t {
    padding: 20px 48px;
    background: #fff;
    display: flex;
    border-radius: 16px;
    position: relative;
    align-items: center;
    .img {
      width: 52px;
      height: 52px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .txt {
      font-weight: 400;
      font-size: 32px;
      color: rgba(0, 0, 0, 0.85);
      line-height: 44px;
      margin: 0 30px 0 22px;
    }
    .arrow {
      width: 22px;
      height: 14px;
      font-size: 0;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .typeUlList {
      position: absolute;
      bottom: -224px;
      left: 90px;
      width: 180px;
      background: #fff;
      box-shadow: 0px 0px 40px 5px rgba(0, 0, 0, 0.1);
      .li {
        padding: 14px 24px;
        font-size: 32px;
        line-height: 50px;
      }
    }
  }
}
</style>
